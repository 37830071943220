import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const visionhorizontale = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Vision horizontale" />
    <h3 className='underline-title'>Vision horizontale</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Là-bas<br/>
        Là-bas à l'horizon<br/>
        Où l'horizon s'embrume<br/>
        Enclavé des vapeurs funestes<br/>
        Relents miasmatiques<br />
        Méphitismes de fosses d'aisance<br />
        Persistants<br />
        Asphyxiants<br />
        Au-delà de la ligne démarcative<br />
        À la frontière des pupilles<br />
        Risque une tête de nuit<br />
        Tête d'une humeur endeuillée<br />
        Tragique<br />
        Tragédie du siècle<br />
      </p>
      <p>
        <br />
        <span className="par-starting"></span>Pitié Seigneur!<br />
      </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, janvier 1975</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default visionhorizontale
